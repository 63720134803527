import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,

    data: [],
    size: 5,
    page: 1,

    total: 0,
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },

    data (state) {
      return state.data || []
    },
    size (state) {
      return state.size || 5
    },
    page (state) {
      return state.page || 1
    },
    total (state) {
      return state.total || 0
    },

    couponFormatter: () => (coupon) => ({
      ...coupon,
    }),
  },
  actions: {
    load ({commit, getters}, options = {}) {
      const payload = {
        size: getters.size,
        page: getters.page,
        ...options,
      }
      commit('setLoading', true)
      commit('setError', null)
      return Vue.http.post('coupons/load', payload)
      .then(response => response.data)
      .then(response => {
        const {
          success,
          message,
          error,
          data,
        } = response
        if (!success) {
          throw message || error || 'Unknown Error'
        }
        const coupons = data.coupons.map(coupon => getters.couponFormatter(coupon))
        commit('setData', coupons)
        commit('setTotal', data.total)
        return(coupons)
      })
      .catch(e => {
        commit('setError', e)
        commit('setData', [])
        commit('setTotal', 0)
        return []
      })
      .finally(() => commit('setLoading', false))
    },
    submit ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('coupons/submit', {
          ...payload,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          const coupon = getters.couponFormatter(data)
          commit('updateData', coupon)
          resolve(coupon)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    delete ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('coupons/delete', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('deleteData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    apply ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('coupons/apply', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },

    setData (state, payload) {
      state.data = payload
    },
    updateData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1, target)
      } else {
        state.data.unshift(target)
      }
    },
    deleteData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1)
      }
    },

    setTotal (state, payload) {
      state.total = payload
    },

    setSize (state, payload) {
      state.size = payload
    },
    setPage (state, payload) {
      state.page = payload
    },

    resetPage (state) {
      state.page = 1
    },
  },
}