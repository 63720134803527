import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },
  },
  actions: {
    create_account ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/create-account', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    check_account ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/check-account', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    account_session ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/account-session', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    register ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/register', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    register_championship ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/register-championship', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    get_invoice ({commit}, invoice_id) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/get-invoice', {
          invoice_id
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    get_receipt ({commit}, invoice_id) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/get-receipt', {
          invoice_id
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    get_account_balance ({commit}, stripe_account_id) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/get-account-balance', {
          stripe_account_id,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    direct_pay ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/direct-pay', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    refund_payment ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('stripe/refund-payment', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
  },
}