import auth from './auth'
import admins from './admins'
import schools from './schools'
import seasons from './seasons'
import stripe from './stripe'
import coupons from './coupons'
import config from './config'

export default {
  auth,
  admins,
  schools,
  seasons,
  stripe,
  coupons,
  config,
}
