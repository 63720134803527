import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'auth',
    component: () => import('../layouts/AuthLayout.vue'),
    redirect: 'login',
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('../views/LoginView.vue'),
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: () => import('../views/ResetPasswordView.vue'),
      },
      {
        path: 'update-password',
        name: 'update-password',
        component: () => import('../views/UpdatePasswordView.vue'),
      },
    ]
  },
  {
    path: '/',
    name: 'student',
    component: () => import('../layouts/StudentLayout.vue'),
    redirect: '/register',
    children: [
      {
        path: '/register',
        name: 'register',
        beforeEnter: (to, from, next) => {
          const preloads = [
            store.dispatch('config/get', {key: 'fees'}),
            store.dispatch('config/get', {key: 'waiver'}),
            store.dispatch('seasons/load', {}),
            store.dispatch('schools/load', {}),
          ]
          Promise.all(preloads)
          next()
        },
        component: () => import('../views/RegisterView.vue')
      },
      {
        path: '/register-championship',
        name: 'register-championship',
        beforeEnter: (to, from, next) => {
          const preloads = [
            store.dispatch('config/get', {key: 'fees'}),
            store.dispatch('config/get', {key: 'waiver'}),
            store.dispatch('seasons/load', {}),
            store.dispatch('schools/load', {}),
          ]
          Promise.all(preloads)
          next()
        },
        component: () => import('../views/ChampionshipRegisterView.vue')
      },
      {
        path: '/invoice/:invoice_id',
        name: 'invoice',
        component: () => import('../views/InvoiceView.vue')
      },
      {
        path: '/receipt/:invoice_id',
        name: 'receipt',
        component: () => import('../views/ReceiptView.vue')
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import("../views/LogoutView.vue"),
  },
  {
    path: '/stripeOauthRedirect',
    name: 'stripeOauthRedirect',
    beforeEnter: (to, from, next) => {
      const isLoggedIn = store.getters['auth/isLoggedIn']
      if (isLoggedIn) {
        next('/admin/school/' + to.query.s_id)
      } else {
        next('/')
      }
    },
  },
  {
    path: '/',
    name: 'admin',
    component: () => import('../layouts/AdminLayout.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: async (to, from, next) => {
      const preloads = [
        store.dispatch('seasons/load', {}),
      ]
      await Promise.all(preloads)
      next()
    },
    children: [
      {
        path: '/admin',
        name: 'superadmin',
        meta: {
          requiresAdmin: true
        },
        beforeEnter: (to, from, next) => {
          const preloads = [
            store.dispatch('config/get', {key: 'fees'}),
            store.dispatch('schools/load', {}),
            store.dispatch('schools/get_championship_products'),
          ]
          Promise.all(preloads)
          next()
        },
        component: () => import('../views/SuperAdminView.vue'),
      },
      {
        path: '/admin/schools',
        name: 'coach',
        component: () => import('../views/CoachView.vue'),
      },
      {
        path: '/admin/school',
        name: 'no_access',
        component: () => import('../views/NoAccessToSchoolView.vue'),
      },
      {
        path: '/admin/school/:id',
        name: 'schooladmin',
        meta: {
          requiresSchoolAccess: true
        },
        beforeEnter: async (to, from, next) => {
          const preloads = [
            store.dispatch('config/get', {key: 'fees'}),
            store.dispatch('schools/get', to.params.id),
            store.dispatch('schools/get_championship_products'),
          ]
          await Promise.all(preloads)
          next()
        },
        component: () => import('../views/SchoolAdminView.vue'),
      },
      {
        path: '/admin/edit-waiver',
        name: 'edit-waiver',
        meta: {
          requiresAdmin: true
        },
        component: () => import('../views/EditWaiverView.vue'),
      },
      {
        path: '/admin/manage-championship',
        name: 'manage-championship',
        meta: {
          requiresAdmin: true
        },
        component: () => import('../views/ManageChampionshipView.vue'),
      },
      {
        path: '/admin/manage-admins',
        name: 'manage-superadmins',
        meta: {
          requiresAdmin: true
        },
        component: () => import('../views/ManageSuperAdminsView.vue'),
      },
    ],
  },
  {
    path: '/payment-portal',
    name: 'payment-portal',
    beforeEnter: async (to, from, next) => {
      const preloads = [
        store.dispatch('config/get', {key: 'fees'}),
        store.dispatch('schools/load', {}),
      ]
      await Promise.all(preloads)
      next()
    },
    component: () => import('../views/SchoolDirectPaymentView.vue'),
  },
  {
    path: "*",
    component: () => import('../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let isLoggedIn = store.getters['auth/isLoggedIn']
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
  let requiresSchoolAccess = to.matched.some(record => record.meta.requiresSchoolAccess)

  if (!isLoggedIn && requiresAuth) {
    next('/login')
    return
  }

  let authUser = store.getters['auth/user']

  if (isLoggedIn && (to.name === 'login')) {
    if (authUser.is_admin) {
      next('/admin')
    } else {
      if (authUser.schools.length > 1) {
        next('/admin/schools')
      } else if (authUser.schools.length == 1) {
        next('/admin/school/' + (authUser.schools[0].id || ''))
      } else {
        next('/admin/school')
      }
    }
    return
  }
  
  if ((to.name === 'admin' || requiresAdmin) && !authUser.is_admin) {
    if (authUser.schools.length > 1) {
      next('/admin/schools')
    } else if (authUser.schools.length == 1) {
      next('/admin/school/' + (authUser.schools[0].id || ''))
    } else {
      next('/admin/school')
    }
    return
  }
  if (to.name === 'admin' && authUser.is_admin) {
    next('/admin')
    return
  }
  if (requiresSchoolAccess && !authUser.is_admin && !authUser.schools.find(school => school.id == to.params.id)) {
    next('/admin/school')
    return
  }

  next()
})

export default router
