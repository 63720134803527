import Vue from 'vue'

export default {
  namespaced: true,
  state: () => ({
    error: null,
    loading: false,
    loadingSchool: false,
    loadingStudent: false,
    loadingOptions: false,
    loadingQuestions: false,
    loadingUsers: false,

    data: [],
    selected: null,
    selectedUsers: [],
    selectedOptions: [],
    selectedQuestions: [],
    championshipProducts: [],

    size: 1000,
    page: 1,
    total: 0,
    sortBy: 'created_at',
    sortDesc: true,
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },
    loadingSchool (state) {
      return state.loadingSchool
    },
    loadingOptions (state) {
      return state.loadingOptions
    },
    loadingQuestions (state) {
      return state.loadingQuestions
    },
    loadingUsers (state) {
      return state.loadingUsers
    },

    data (state) {
      return (state.data || []).map(school => ({
        ...school,
      }))
    },

    selected (state) {
      return state.selected
    },
    selectedUsers (state) {
      return state.selectedUsers
    },
    selectedOptions (state) {
      return state.selectedOptions
    },
    selectedQuestions (state) {
      return state.selectedQuestions
    },
    championshipProducts (state) {
      return state.championshipProducts
    },

    activeSeason (state, getters, rootState, rootGetters) {
      return rootGetters['seasons/activeSeason'] || {id: null}
    },

    size: (state) => state.size || 10,
    page: (state) => state.page || 1,
    sortBy: (state) => state.sortBy || 'created_at',
    sortDesc: (state) => state.sortDesc ? 'DESC' : 'ASC',
  },
  actions: {
    load ({commit, getters}, options) {
      const payload = {
        size: getters.size,
        page: getters.page,
        sortBy: getters.sortBy,
        sortDesc: getters.sortDesc,
        ...options,
      }
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        Vue.http.post('schools/load', {
          ...payload,
          season_id: getters.activeSeason.id,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setData', data.schools)
          commit('setTotal', data.total)
          resolve(getters.data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    submit ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('schools/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('updateData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    update ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoadingSchool', true)
        commit('setError', null)
        Vue.http.post('schools/' + payload.school_id + '/update', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('updateData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingSchool', false))
      })
    },
    delete ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        commit('setError', null)
        Vue.http.post('schools/delete', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('deleteData', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoading', false))
      })
    },
    search ({commit}, payload) {
      return new Promise((resolve) => {
        Vue.http.post('schools/search-signups', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => {
          commit('setError', e),
          resolve({
            data: [],
            total: 0,
          })
        })
      })
    },
    get ({commit, getters}, school_id) {
      return new Promise((resolve, reject) => {
        commit('setLoadingSchool', true)
        Vue.http.post('schools/' + school_id, {
          season_id: getters.activeSeason.id,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelected', data)
          resolve(getters.selected)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingSchool', false))
      })
    },
    get_users ({commit, getters}, school_id) {
      return new Promise((resolve, reject) => {
        commit('setLoadingUsers', true)
        Vue.http.post('schools/' + school_id + '/users')
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelectedUsers', data)
          resolve(getters.selectedUsers)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingUsers', false))
      })
    },
    submit_user ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoadingUsers', true)
        commit('setError', null)
        Vue.http.post('schools/' + payload.school_id + '/users/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelectedUsers', data)
          resolve()
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingUsers', false))
      })
    },
    delete_user ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoadingUsers', true)
        commit('setError', null)
        Vue.http.post('schools/' + payload.school_id + '/users/delete', {
          id: payload.id,
          school_id: payload.school_id,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelectedUsers', data)
          resolve()
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingUsers', false))
      })
    },
    get_championship_products ({commit, getters}) {
      return new Promise((resolve, reject) => {
        commit('setLoadingOptions', true)
        Vue.http.post('schools/get-championship-products')
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setChampionshipProducts', data)
          resolve(getters.championshipProducts)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingOptions', false))
      })
    },
    get_options ({commit, getters}, school_id) {
      return new Promise((resolve, reject) => {
        commit('setLoadingOptions', true)
        Vue.http.post('schools/' + school_id + '/options')
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelectedOptions', data)
          resolve(getters.selectedOptions)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingOptions', false))
      })
    },
    submit_option ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoadingOptions', true)
        commit('setError', null)
        Vue.http.post('schools/' + payload.school_id + '/options/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelectedOptions', data)
          resolve()
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingOptions', false))
      })
    },
    delete_option ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoadingOptions', true)
        commit('setError', null)
        Vue.http.post('schools/' + payload.school_id + '/options/delete', {
          id: payload.id,
          school_id: payload.school_id,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelectedOptions', data)
          resolve()
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingOptions', false))
      })
    },
    get_questions ({commit, getters}, school_id) {
      return new Promise((resolve, reject) => {
        commit('setLoadingQuestions', true)
        Vue.http.post('schools/' + school_id + '/questions')
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelectedQuestions', data)
          resolve(getters.selectedQuestions)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingQuestions', false))
      })
    },
    submit_question ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoadingQuestions', true)
        commit('setError', null)
        Vue.http.post('schools/' + payload.school_id + '/questions/submit', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelectedQuestions', data)
          resolve()
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingQuestions', false))
      })
    },
    delete_question ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoadingQuestions', true)
        commit('setError', null)
        Vue.http.post('schools/' + payload.school_id + '/questions/delete', {
          id: payload.id,
          school_id: payload.school_id,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('setSelectedQuestions', data)
          resolve()
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingQuestions', false))
      })
    },
    submit_price ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoadingSchool', true)
        commit('setError', null)
        Vue.http.post('schools/' + payload.school_id + '/submit-price', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('updateSchoolPrice', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingSchool', false))
      })
    },
    submit_payments_online_status ({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setLoadingSchool', true)
        commit('setError', null)
        Vue.http.post('schools/' + payload.school_id + '/submit-payments-online-status', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          commit('updateSchoolPaymentsOnlineStatus', data)
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
        .finally(() => commit('setLoadingSchool', false))
      })
    },
    get_registrants ({commit, getters}, payload) {
      return new Promise((resolve) => {
        Vue.http.post('schools/get-registrants', {
          ...payload,
          season_id: getters.activeSeason.id,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => {
          commit('setError', e),
          resolve({
            data: [],
            total: 0,
          })
        })
      })
    },
    update_registrant ({commit}, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.post('schools/update-registrant', payload)
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
      })
    },
    delete_registrant ({commit}, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.post('schools/delete-registrant', {
          id: payload.id,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve()
        })
        .catch(e => { commit('setError', e), reject(e) })
      })
    },
    get_championship_schools ({commit}) {
      return new Promise((resolve, reject) => {
        Vue.http.post('schools/get-championship-schools')
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
      })
    },
    load_payments ({commit, getters}, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.post('schools/load-payments', {
          ...payload,
          season_id: getters.activeSeason.id,
        })
        .then(response => response.data)
        .then(response => {
          const {
            success,
            message,
            error,
            data,
          } = response
          if (!success) {
            throw message || error || 'Unknown Error'
          }
          resolve(data)
        })
        .catch(e => { commit('setError', e), reject(e) })
      })
    },
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setLoadingSchool (state, payload) {
      state.loadingSchool = payload
    },
    setLoadingStudent (state, payload) {
      state.loadingStudent = payload
    },
    setLoadingOptions (state, payload) {
      state.loadingOptions = payload
    },
    setLoadingQuestions (state, payload) {
      state.loadingQuestions = payload
    },
    setLoadingUsers (state, payload) {
      state.loadingUsers = payload
    },

    setData (state, payload) {
      state.data = payload
    },
    updateData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1, target)
      } else {
        state.data.unshift(target)
      }
    },
    deleteData (state, target) {
      const rowIndex = state.data.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.data.splice(rowIndex, 1)
      }
    },
    setSelected (state, target) {
      state.selected = target
    },
    setSelectedUsers (state, users) {
      state.selectedUsers = users
    },
    setSelectedOptions (state, options) {
      state.selectedOptions = options
    },
    setSelectedQuestions (state, questions) {
      state.selectedQuestions = questions
    },
    setChampionshipProducts (state, products) {
      state.championshipProducts = products
    },
    updateUserData (state, target) {
      const rowIndex = state.selectedUsers.findIndex(row => row.id == target.id)
      if (rowIndex > -1) {
        state.selectedUsers.splice(rowIndex, 1, target)
      } else {
        state.selectedUsers.unshift(target)
      }
    },
    updateSchoolPrice (state, target) {
      state.selected.price = target.price
    },
    updateSchoolPaymentsOnlineStatus (state, target) {
      state.selected.is_payments_online = target.is_payments_online
    },

    setTotal (state, payload) {
      state.total = payload
    },

    setSize (state, payload) {
      state.size = payload
    },
    setPage (state, payload) {
      state.page = payload
    },
    setSortBy (state, payload) {
      state.sortBy = payload
    },
    setSortDesc (state, payload) {
      state.sortDesc = payload
    },

    resetPage (state) {
      state.page = 1
    },
  },
}