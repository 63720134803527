import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => ({
    error: null,
    loading: false,
    appTitle: 'Scholastic Surf Signup',
    appVersion: process.env.VUE_APP_VERSION || '0.1.0',
    apiHost: process.env.VUE_APP_API_HOST,
  }),
  getters: {
    error (state) {
      return state.error
    },
    loading (state) {
      return state.loading
    },
    appTitle: state => state.appTitle,
    appVersion: state => state.appVersion,
    apiHost: state => state.apiHost,
  },
  mutations: {
    setError (state, payload) {
      state.error = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
  },
  actions: {
  },
  modules: modules,
})
