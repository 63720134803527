import Vue from 'vue'
import router from "@/router/index"

export default {
  namespaced: true,
  state: () => ({
    auth_status: '',
    token: localStorage.getItem('token') || '',
    user : null,
  }),
  getters: {
    isLoggedIn: state => !!state.token,
    status: state => state.auth_status,
    loading: state => state.auth_status == 'loading',
    error: state => state.auth_status == 'error',
    success: state => state.auth_status == 'success',
    user: state => state.user || {},
  },
  mutations: {
    auth_request(state) {
      state.auth_status = 'loading'
    },
    auth_status(state, status) {
      state.auth_status = status
    },
    auth_success(state, {token, user}) {
      state.auth_status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state) {
      state.auth_status = 'error'
    },
    logout(state) {
      state.auth_status = ''
      state.token = ''
      state.user = null
    },
    user_fill(state, {user}) {
      state.auth_status = 'success'
      state.user = user
    },
  },
  actions: {
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.post('auth/login', payload)
        .then(resp => {
          const {
            success,
            message,
            error,
            token,
            user,
          } = resp.data
          if (!success) {
            throw new Error(message || error || 'Unknown Error')
          }
          localStorage.setItem('token', token)
          Vue.http.headers.common['Authorization'] = 'Bearer ' + token
          commit('auth_success', {token, user})
          resolve(user)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    resetRequest({commit}, payload) {
      return new Promise((resolve, reject) => {
        Vue.http.post('auth/reset-password', payload)
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    checkResetToken({commit}, payload) {
      commit('auth_request')
      return new Promise((resolve, reject) => {
        Vue.http.post('auth/check-reset-token', payload)
        .then(resp => {
          if (!resp.data.success) {
            throw resp.data
          }
          commit('auth_status', 'success')
          resolve(resp.data)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    updatePassword({commit}, payload) {
      commit('auth_request')
      return new Promise((resolve, reject) => {
        Vue.http.post('auth/update-password', payload)
        .then(resp => {
          if (!resp.data.success) {
            throw resp.data
          }
          commit('auth_status', '')
          resolve(resp.data)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    logout({commit}) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        delete Vue.http.headers.common['Authorization']
        router.push('/login', function() {
          resolve()
        })
      })
    },
    getUser({commit}) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        Vue.http.post('auth/get', {})
        .then(resp => {
          const {
            user,
          } = resp.data
          commit('user_fill', {user})
          resolve()
        }).catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          delete Vue.http.headers.common['Authorization']
          reject(err)
        })
      })
    },
    update() {
    },
  }

}